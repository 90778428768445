/* ------------------------------------------------------------------------------------------------ */
/* Custom Datetimepicker */
/* ------------------------------------------------------------------------------------------------ */

.datepicker {
  table tr td {
    &.active,
    &.active:hover,
    &.active.disabled,
    &.active.disabled:hover {
      background-image: none;
      background-color: $primary; }
    &.active:hover,
    &.active:hover:hover,
    &.active.disabled:hover,
    &.active.disabled:hover:hover,
    &.active:active,
    &.active:hover:active,
    &.active.disabled:active,
    &.active.disabled:hover:active,
    &.active.active,
    &.active:hover.active,
    &.active.disabled.active,
    &.active.disabled:hover.active,
    &.active.disabled,
    &.active:hover.disabled,
    &.active.disabled.disabled,
    &.active.disabled:hover.disabled,
    &.active[disabled],
    &.active:hover[disabled],
    &.active.disabled[disabled],
    &.active.disabled:hover[disabled] {
      background-color: $primary; } } }
