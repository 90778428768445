/* ------------------------------------------------------------------------------------------------ */
/* custom owl */
/* ------------------------------------------------------------------------------------------------ */

.owl-carousel {
  position: relative;
  // overflow: hidden
  .owl-loaded {
    overflow: visible; }
  .owl-stage-outer {
    overflow-x: hidden;
    // padding-top: 60px
    .owl-stage {
      .owl-item {
        position: relative; } } }
  .owl-nav {
    position: absolute;
    top: calc(50% - 15px);
    width: 100%;
    .owl-prev,
    .owl-next {
      height: 20px;
      width: 20px;
      font-size: 12px;
      border-radius: 50%;
      border: 1px solid $primary;
      color: $primary;
      position: absolute;
      @include transition(all .3s);
      .fal {
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 4px); }
      &:hover {
        transform: scale(1.3);
        color: lighten($primary, 30%);
        opacity: .8; }
      &.disabled {
        opacity: 0;
        visibility: hidden; } }
    .owl-prev {
      left: -60px; }
    .owl-next {
      text-align: right;
      right: -60px;
      .fal {
        left: calc(50% - 2px); } } }
  .owl-dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 25px;
    line-height: 10px;
    .owl-dot {
      display: inline-block;
      margin: 0 5px;
      span {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #FFF;
 }        // border: 1px solid #FFF
      &.active {
        span {
          background-color: $blue; } } } }
  @include maxtablet {
    .owl-nav {
      .owl-nav {
        .owl-prev {
          left: -10px; }
        .owl-next {
          right: -10px; } } } } }
.slider-xs {
  .owl-dots {
    bottom: 0;
    .owl-dot {
      span {
        background-color: $border; } } } }
@include mobile {
  .owl-carousel {
    padding-bottom: 20px; } }
