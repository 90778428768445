/* ------------------------------------------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------------------------------------------ */

header {
  // box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25)
  border-bottom: 1px solid $border;
  position: relative;
  top: 0;
  width: 100%;
  min-height: 50px;
  transition: all 0.5s;
  z-index: 5;
  background: #FFF;
  &.header-home {
    background: transparent;
    position: fixed;
    padding: 35px 0 0;
    border-bottom: 0;
    .hh-wrap {
      width: 1240px;
      margin: 0 auto;
      background: #FFF;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25); } }
  .logo {
    max-width: 220px;
    display: inline-block;
    padding: 20px 0 25px;
    img {
      max-width: 100%; }
    &:hover {
      opacity: .7; } }
  nav {
    ul:not(.sub-wrap),
    ul:not(.sub-menu) {
      font-size: 0;
      line-height: 0;
      text-align: right;
      & > li {
        list-style: none;
        display: inline-block;
        height: 100%;
        padding: 33px 20px;
        border-left: 1px solid rgba(226,226,226,0.2);
        position: relative;
        > a {
          text-align: center;
          padding: 0 20px;
          font-size: 14px;
          line-height: 20px;
          color: #686E70;
          position: relative;
          &:hover {
            color: #2187A4; } }
        &.current-menu-item,
        &.current_page_parent {
          >a {
            color: #2187A4; } }
        &:last-child {
          padding: 33px 0 33px 20px;
          a {
            padding: 0 0 0 15px; } }
        &.has-sub,
        &.menu-item-has-children {
          >a {
            // pointer-events: none
            &:after {
              content: "\f0d7";
              font-family: FontAwesome;
              font-size: 16px;
              position: absolute;
              right: 3px;
              color: $text;
              top: 0px;
              transition: -webkit-transform .6s;
              transition: transform .6s;
              transition: transform .6s, -webkit-transform .6s; } }
          &:hover {
            > a {
              &:after {
                transform: rotate(-180deg);
                &:hover {
                  color: #2187A4; } } } } }
        .sub-menu {
          position: absolute;
          left: calc(50% - 110px);
          top: calc(100%  + 3px);
          width: 280px;
          // min-width: calc(max-content + 30px)
          background-color: #fff;
          text-align: left;
          max-height: 0;
          opacity: 0;
          border-radius: 2px;
          visibility: hidden;
          transition: all .5s;
          padding: 15px 30px;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
          border: 1px solid #e2e2e2;
          ul {
            display: block;
            margin: 0 auto;
            & > li {
              list-style: none;
              font-size: 14px;
              line-height: 30px;
              &.current-menu-item,
              &.current_page_parent {
                >a {
                  color: #2187A4 !important; } }
              a {
                font-size: 14px;
                line-height: 20px;
                color: #686E70;
                position: relative;
                &:hover {
                  color: #2187A4; }
                .stat {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 1.5em;
                  position: absolute;
                  left: calc(100% + 10px);
                  top: 0;
                  display: inline-block;
                  white-space: nowrap;
                  &.ongoing {
                    color: #2187A4; }
                  &.on-progress {
                    color: #73bf43; }
                  &.soldout {
                    color: #E62A2C; }
                  @include responsive(1200px, 0) {
                    left: auto;
                    top: 0px;
                    right: calc(100% + 10px); } } } } }

          .sub-wrap {
            &:before {
              position: absolute;
              content: "";
              border-bottom: 13px solid #e2e2e2;
              border-left: 11px solid transparent;
              border-right: 11px solid transparent;
              width: 0;
              height: 0;
              top: -11px;
              left: calc(50% - 11px);
              z-index: 3; }
            &:after {
              position: absolute;
              content: "";
              border-bottom: 13px solid #fff;
              border-left: 11px solid transparent;
              border-right: 11px solid transparent;
              width: 0;
              height: 0;
              top: -10px;
              left: calc(50% - 11px);
              z-index: 3; } }
          .sub-menu:before {
            border-bottom-color: #e2e2e2;
            top: -12px;
            z-index: 2; } }
        &.has-sub,
        &.menu-item-has-children {
          &:hover {
            .sub-menu {
              max-height: 999px;
              opacity: 1;
              // padding: 15px 30px
              visibility: visible; } } } } }

    .menu-item-has-children {
      > .sub-menu {
        position: relative;
        > li {
          padding: 0;
          line-height: 30px;
          display: block;
          border: 0;
          > a {
            padding: 0;
            line-height: 20px; }
          &:last-child {
            padding: 0;
            line-height: 30px;
            > a {
              padding: 0; } }
          &.current-menu-item {
            > a {
              color: #2187A4 !important; } } }
        &:before {
          position: absolute;
          content: "";
          border-bottom: 13px solid #e2e2e2;
          border-left: 11px solid transparent;
          border-right: 11px solid transparent;
          width: 0;
          height: 0;
          top: -12px;
          left: calc(50% - 11px);
          z-index: 3; }
        &:after {
          position: absolute;
          content: "";
          border-bottom: 13px solid #fff;
          border-left: 11px solid transparent;
          border-right: 11px solid transparent;
          width: 0;
          height: 0;
          top: -10px;
          left: calc(50% - 11px);
          z-index: 3;
          padding: 0; } } }
    .has-sub {
      > .sub-menu {
        > .sub-wrap {
          > li {
            line-height: 30px;
            display: block;
            border: 0;
            text-align: left;
            border: 0;
            padding: 0;
            > a {
              padding: 0;
              line-height: 20px; }
            &:last-child {
              padding: 0;
              line-height: 30px;
              > a {
                padding: 0; } } } } } } }

  &.fixed {
    position: fixed;
    transform: translateY(-160px);
    margin-top: 0;
    border-bottom: 1px solid $border;
    .hh-wrap {
      width: 100%;
      box-shadow: none; } }
  &.show {
    transform: translateY(0); }
  &.fixed.show {
    transform: translateY(0);
    padding: 0;
    background: #FFF;
    margin-top: 0;
    .logo {
      padding: 5px 0 15px; }
    nav {
      ul:not(.sub-menu) {
        > li {
          padding: 15px; } }
      .sub-menu {
        li {
          padding: 0 !important; } } } }

  .current-menu-item,
  .current_page_parent {
    ul:not(.sub-menu) {
        > li {
          > a {
            color: #2187A4 !important; } } } }

  .icon-menu {
    display: none;
    position: absolute;
    right: 20px;
    top: 30px;
    width: 30px;
    @include transition(all .5s);
    span {
      display: block;
      width: 30px;
      height: 4px;
      background-color: $primary;
      position: absolute;
      @include transition(all .5s);
      &:nth-child(1) {
        top: 0; }
      &:nth-child(2) {
        top: 8px; }
      &:nth-child(3) {
        top: 16px; } } }

  @include responsive(1200px) {
    &.header-home {
      padding: 0;
      .hh-wrap {
        width: 100%; } }
    &.fixed.show {
      .icon-menu {
        top: 20px; }
      nav {
        top: 55px;
        height: calc(100vh - 60px);
        ul:not(.sub-menu) {
          li {
            padding: 15px; } }
        .sub-menu {
          li {
            padding: 0 0 20px 0 !important; } } } }
    .icon-menu {
      display: block; }
    nav {
      position: fixed;
      top: 80px;
      transform: translateX(300px);
      height: calc(100vh - 80px);
      width: 300px;
      right: 0;
      background: rgba(42, 42, 42, 0.9);
      z-index: 5;
      padding: 20px;
      transition: -webkit-transform 0.5s;
      transition: transform 0.5s;
      transition: transform 0.5s, -webkit-transform 0.5s;
      overflow: auto;
      ul:not(.sub-wrap),
      ul:not(.sub-menu) {
        li {
          display: block;
          padding: 15px 0;
          transform: translateX(100px);
          border-left: 0;
          transition: all .3s;
          &.menu-item-has-children {
            &:hover {
              .sub-menu {
                max-height: 0px;
                opacity: 0;
                visibility: hidden; } } }
          &.has-sub {
            > a {
 } }              // pointer-events: none
          &:first-child {
            padding: 15px 0; }
          &:last-child {
            padding: 15px 0 0 0; }
          a {
            padding: 0;
            color: #FFF; }
          &.has-sub {
            >a {
              &:after {
                color: #FFF;
                right: auto;
                left: -15px; } } }
          .sub-menu {
            position: relative;
            left: auto;
            right: auto;
            width: auto;
            background: transparent;
            border: 0;
            padding: 0;
            box-shadow: none;
            .sub-wrap {
              &:before,
              &:after {
                display: none; }
              li {
                text-align: right;
                line-height: 20px;
                padding-bottom: 15px;
                a {
                  color: #FFF; }
                &:last-child {
                  padding-top: 0; } } } } }
        .sub-wrap {
          li {
            border-bottom: 0; } } } } }
  @include mobile {
    nav {
      transform: translateX(100vw);
      width: 100%; } } }


.menu-open {
  overflow: hidden;
  position: relative;
  .icon-menu {
    span {
      &:nth-child(1) {
        top: 8px;
        transform: rotate(45deg); }
      &:nth-child(2) {
        top: 8px;
        transform: scale(0); }
      &:nth-child(3) {
        top: 8px;
        transform: rotate(-45deg); } } }
  nav {
    transform: translateX(0) !important;
    ul:not(.sub-wrap) {
      li {
        transform: translateX(0);
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        &:nth-child(1) {
          transition-delay: .4s, .4s; }
        &:nth-child(2) {
          transition-delay: .45s, .45s; }
        &:nth-child(3) {
          transition-delay: .5s, .5s; }
        &:nth-child(4) {
          transition-delay: .55s, .55s; }
        &:nth-child(5) {
          transition-delay: .6s, .6s; }
        &:last-child {
          padding: 15px 0 0 0;
          border-bottom: 0; }
        &.has-sub:hover .sub-menu {
          max-height: 0;
          opacity: 0;
          // padding: 15px 30px
          visibility: hidden; }
        &.has-sub {
          &:hover {
            > a {
              &:after {
                transform: rotate(0); } } } }
        // &.sub-open
        //   .sub-menu
        //     padding-top: 20px
        //     max-height: 999px !important
        //     opacity: 1 !important
        //     visibility: visible !important
        //a
        //  &:after
 } } }        //    transform: rotate(-180deg) !important
  @include responsive(1200px) {
    nav {
      .menu-item-has-children {
        > a {
          &:after {
            color: #FFF !important;
            right: auto !important;
            left: -15px; } }
        .sub-menu {
          text-align: right;
          &:before,
          &:after {
            display: none; }
          > li {
            border-bottom: 0 !important;
            line-height: 20px;
            padding: 0 0 15px 0;
            &:first-child {
              padding: 15px 0 15px 0; }
            &:last-child {
              padding-top: 0; }
            > a {
              color: #FFF; } } } }
      &.sub-closed {
        &:hover {
          .sub-menu {
            max-height: 0;
            visibility: hidden;
            opacity: 0; } } } } } }

.sub-closed {
  > a {
    &:after {
      display: none; } }
  .fas {
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    line-height: 1.5;
    color: #FFF;
    position: relative;
    transition: all .3s; }
  &:hover {
    .sub-menu {} }

  .sub-menu {
    max-height: 0;
    visibility: hidden;
    opacity: 0; } }
.sub-open {
  .fas {
    transform: rotate(180deg); }
  .sub-menu {
    max-height: 999999px !important;
    visibility: visible !important;
    opacity: 1 !important; } }
