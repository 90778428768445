@keyframes animate {
  0% {
    opacity: 0;
    z-index: 0;
    transform: translateY(-100px); }
  99% {
    opacity: 1;
    z-index: 0;
    transform: translateY(-10px); }
  100% {
    transform: translateY(0px);
    z-index: 1; } }

@keyframes mouse-wheel {
  0% {
    opacity: 0;
    z-index: 0;
    transform: translateY(-30px); }
  99% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    transform: translateY(0px);
    opacity: 0; } }

.animate.header-bg {
  transform: translateY(-100px);
  opacity: 0;
  transition: transform .5s ease, opacity .5s ease;
  transition-delay: 1s;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; } }

.animate.header-logo {
  transform: translateY(50px);
  opacity: 0;
  transition: transform .5s ease, opacity .5s ease;
  transition-delay: 2s;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; } }

.animate.header-nav {
  transform: translateY(50px);
  opacity: 0;
  transition: all .5s ease;
  transition-delay: 3s;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; }
  @include maxtablet {
    transform: translateX(300px);
    opacity: 1;
    transition: all .5s;
    transition-delay: 0s;
    &.animate-in {
      transform: translateX(300px);
      opacity: 1; } }
  @include mobile {
    transform: translateX(100vw);
    &.animate-in {
      transform: translateX(100vw); } } }

.animate.fade-in {
  transform: translateY(50px);
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; } }

.animate.fade-inb {
  transform: translateY(50px);
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; } }

.animate.fade-pop {
  bottom: -75px;
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    bottom: -45px;
    opacity: 1; } }

.animate.fade-in.slow {
  transform: translateY(100px);
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; }
  @include mobile {
    transform: translateY(10px); } }

.animate.fade-out {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; }
  @include mobile {
    transform: translateY(10px); } }

.animate.fade-outs {
  opacity: 0;
  z-index: 0;
  transition: all 4s ease, opacity 2s, z-index 1s 2s ease;
  &.animate-in {
    opacity: 1;
    z-index: 2; }
  @include mobile {
 } }    // transform: translateY(10px)

.detail-content {
  .animate.fade-outs {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.animate-in {
      transform: translateY(0);
      opacity: 1; } } }
.section-dbrocure,
.brocure-detail {
  &.animate.fade-outs {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.animate-in {
      transform: translateY(0);
      opacity: 1; } } }

.animate.fade-in-left {
  transform: translateX(-100px);
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    transform: translateY(0);
    opacity: 1; } }

.animate.fade-in-left.before {
  &:before {
    transform: translateX(-100px);
    opacity: 0;
    transition: all 1s ease; }
  &.animate-in {
    &:before {
      transform: translateY(0);
      opacity: 1; } } }

.animate.fade-in-right {
  transform: translateX(200px);
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    transform: translateX(0);
    opacity: 1; }
  @include mobile {
    transform: translateY(10px); } }
.animate.fade-only {
  opacity: 0;
  transition: all 1s ease;
  &.animate-in {
    opacity: 1; } }

.animate.fade-scale {
  opacity: 0;
  transform: scale(.8);
  transition: all 1s ease;
  &.animate-in {
    opacity: 1;
    transform: scale(1); } }

.animate.fade-in.delay,
.animate.fade-inb.delay,
.animate.fade-outs.delay {
  &:nth-child(1) {
    transition-delay: .2s; }
  &:nth-child(2) {
    transition-delay: .4s; }
  &:nth-child(3) {
    transition-delay: .6s; }
  &:nth-child(4) {
    transition-delay: .8s; }
  &:nth-child(5) {
    transition-delay: 1s; }
  &:nth-child(6) {
    transition-delay: 1.2s; }
  &:nth-child(7) {
    transition-delay: 1.4s; }
  &:nth-child(8) {
    transition-delay: 1.6s; }
  &:nth-child(9) {
    transition-delay: 1.8s; } }

.delay-1 {
  transition-delay: 1s !important; }
.delay-2 {
  transition-delay: 2s !important; }
.delay-3 {
  transition-delay: 3s !important; }
.delay-4 {
  transition-delay: 4s !important; }
.delay-5 {
  transition-delay: 6s !important; }

.arrow-down {}



// .slider-p
//   .owl-item
//     .card
//       // transform: translateY(60px)
//       opacity: 0
//       // transition: all 1s ease
//       animation-timing-function: ease-out
//       &.animate-in
//         transform: translateY(0)
//         opacity: 1
//     &:nth-child(1),
//     &:nth-child(3)
//       .card
//         transition-delay: .6s
//     &:nth-child(2),
//     &:nth-child(4)
//       .card
//         transition-delay: .4s
//     &:nth-child(3),
//     &:nth-child(6)
//       .card
//         transition-delay: .2s
//     &:nth-child(5),
//     &:nth-child(7)
//       .card
//         transition-delay: .6s
//     &.center
//       .card
//         // transform: translateY(80px)
//         opacity: 0
//         // transition: all .4s ease
//         // transition-delay: .4s
//         // animation-timing-function: ease-out
//         &.animate-in
//           // transform: translateY(-60px)
//           opacity: 1
//   +mobile
//     .owl-item
//       &.center
//         .card
//           transform: translateY(0)
//           &.animate-in
//             transform: translateY(0) !important
