/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */

* {
  outline: none; }

.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

.icon {
  display: inline-block;
  vertical-align: middle; }
p {
  text-align: justify;
  &:last-child {
    margin-bottom: 0; } }
br {
  @include mobile {
    display: none; } }
hr {
  border-color: $border;
  margin: 0 auto 50px;
  max-width: 1170px;
  @include maxtablet {
    max-width: 100%; }
  @include mobile {
    margin: 0 auto 15px; } }

#preloading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  background-image: url(../images/rolling.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
  z-index: 99; }

#myList {
  .col-sm-4 {
    display: none; } }

.section-part {
  padding: 60px 0;
  &.pb0 {
    padding-bottom: 0; }
  &.pb30 {
    padding-bottom: 30px; }
  &.pb60 {
    padding-bottom: 60px; }
  &:last-child {
    padding-bottom: 120px; }
  @include maxtablet {
    padding: 30px 0;
    &:last-child {
      padding-bottom: 60px; } }
  @include mobile {
    padding: 15px 0;
    &:last-child {
      padding-bottom: 30px; } } }

.title {
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  margin-bottom: 0;
  color: #3F474A;
  &.main {
    margin-bottom: 60px; }
  span {
    color: #2187A4; }
  &.center {
    text-align: center; }
  @include maxtablet {
    font-size: 33px;
    line-height: 43px;
    &.main {
      margin-bottom: 30px; } }
  @include mobile {
    font-size: 21px;
    line-height: 33px; } }

.content-text-wrap {
  .content-text {
    max-width: 945px; } }

.btn-link {
  color: #FFF;
  opacity: .4;
  font-weight: normal;
  position: relative;
  line-height: 24px;
  &:hover {
    text-decoration: none;
    color: #FFF;
    opacity: 1;
    &:after {
      opacity: 1; } }
  &:after {
    content: "";
    opacity: .4;
    width: 70px;
    height: 2px;
    display: block;
    background-color: #FFF; } }
dl {
  margin-bottom: 30px;
  dt {
    float: left;
    font-weight: 400;
    color: #686E70; }
  dd {
    padding-left: 95px;
    margin-bottom: 5px;
    color: #686E70; } }

.modal {
  background: rgba(33, 135, 164, 0.8);
  .modal-dialog {
    width: 770px;
    @include maxtablet {
      width: 85%; }
    @include mobile {
      width: auto; }
    &.modal-sm {
      max-width: 375px;
      width: auto;
      padding: 20px;
      .modal-content {}
      .modal-body {
        padding: 20px;
        p {
          font-size: 14px; } }
      label {
        font-size: 14px; }
      .btn-link,
      .btn {
        background-color: $blue;
        color: #FFF;
        opacity: 1;
        padding: 6px 12px;
        line-height: 1.5;
        border-radius: 4px;
        &:after {
          display: none; }
        &:hover {
          background-color: lighten($blue, 5%);
          text-decoration: none; }
        // color: $blue
        // padding: 0
        // position: relative
        // opacity: 1
        // font-size: 16px
        // line-height: 24px
        // &:after
        //   content: ""
        //   opacity: 1
        //   width: 70px
        //   height: 2px
        //   display: block
 } } }        //   background-color: $blue
  .modal-content {
    position: relative; }
  .modal-close {
    cursor: pointer;
    color: #FFF;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    right: -60px;
    top: 25px;
    text-align: center;
    opacity: 1;
    transition: opacity .5s;
    display: none;
    span {
      display: block; }
    &:before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      background-image: url(../images/icon-close.svg);
      margin: 0 auto 10px; }
    &:hover {
      opacity: .5; } }

  .modal-dialog .modal-content {
    box-shadow: 0 20px 60px 0 rgba(0,0,0,0.5);
    border: 0;
    border-radius: 4px;
    background-color: #FFF; }
  .modal-header {
    border: 0;
    padding: 60px 60px 0; }
  .modal-title {
    font-size: 21px;
    line-height: 30px;
    font-weight: 600;
    &:last-child {
      margin-bottom: 0; } }
  .modal-body {
    padding: 30px 60px; }
  .modal-item {
    padding: 30px 60px;
    border-bottom: 1px solid $border;
    .wrap-item {
      padding-left: 180px;
      position: relative; }
    .box-img {
      width: 150px;
      position: absolute;
      left: 0;
      top: 0;
      margin-bottom: 20px;
      .img {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; } }
    h3 {
      font-size: 18px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 10px; }
    p {
      margin-bottom: 20px; }
    *:last-child {
      margin-bottom: 0; }

    &:last-of-type {
      padding-bottom: 60px;
      border-bottom: 0; } }

  .form-control {
    height: 30px;
    padding: 6px 0; }

  &.modal-video {
    .modal-body {
      padding: 20px;
      center {
        padding: 15px 0;
        p {
          font-size: 14px;
          font-weight: 600; } }
      br {
        display: none; } }

    .video-embed {
      width: 100%;
      padding-top: 56.2%;
      position: relative;
      iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; } } }

  .modal-dialog {
    min-height: calc(100% - (1.75rem * 2));
    margin: 1.75rem auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    .modal-content {
      position: relative;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      pointer-events: auto;
      width: 100%; } }

  @include maxtablet {
    .modal-dialog {
      margin-top: 80px; }
    .modal-close {
      top: -50px;
      right: 0; }
    .modal-header {
      padding: 30px 40px 0; }
    .modal-body,
    .modal-item {
      padding: 30px 40px; } }

  @include mobile {
    .modal-item {
      .wrap-item {
        padding-left: 0; }
      .box-img {
        position: relative; } } } }

.box-video {
  position: relative;
  .play {
    position: absolute;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    background-color: #000;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    transition: all .3s;
    z-index: 5;
    i {
      position: relative;
      left: 4px; }
    &:hover {
      background-color: #FFF;
      color: $blue; } }
  @include maxtablet {
    .play {
      width: 50px;
      height: 50px;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      font-size: 18px;
      i {
        left: 2px; } } } }

.card {
  transition: all .5s .1s ease;
  margin-bottom: 60px;
  .box-img {
    position: relative;
    width: 100%;
    padding-top: 100%;
    margin-bottom: 20px;
    .img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: all .5s; } }
  .title {
    margin-bottom: 20px;
    a {
      color: #3F474A;
      &:hover {
        color: $blue; } } }

  .btn-link {
    color: $blue;
    transition: all .5s;
    opacity: 1;
    &:after {
      background-color: $blue;
      opacity: 1;
      transition: all .5s; }
    &:hover {
      opacity: .8;
      &:after {
        opacity: .8; } } } }

.box-img {
  overflow: hidden;
  &:hover {
    .img {
      transform: scale(1.03); } }
  .img {
    transition: -webkit-transform .6s;
    transition: transform .6s;
    transition: transform .6s,-webkit-transform .6s; } }


.tag {
  font-size: 12px;
  line-height: 14px;
  color: #FFF;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 2px;
  display: inline-block;
  &.t-sold {
    background-color: #BF0000; }
  &.t-ongoing {
    background-color: #2187A4; }
  &.t-development {
    background-color: #686E70; }
  &.t-completed {
    background-color: $green; }
  @include maxtablet {
    font-size: 12px;
    white-space: nowrap;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

.content-text {
  p:last-child {
    margin-bottom: 0; } }

.form-control {
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
  padding-left: 0;
  max-width: 340px;
  height: 40px;
  border-bottom: 1px solid $border; }


.wa-box {
  position: fixed;
  bottom: 30px;
  right: 60px;
  z-index: 3;
  padding: 25px 20px;
  // background: linear-gradient(180deg, #60D36B 0%, #29B53F 100%)
  border-radius: 8px 8px 0 0;
  text-align: center;
  font-weight: 600;
  // box-shadow: 0 20px 30px 0 rgba(0,0,0,0.5);
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease;
  transform: translateY(60px);
  &.showed {
    max-height: 9999px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  &.ishowed {
    max-height: 9999px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  h3 {
    font-size: 16px;
    line-height: 22px;
    color: #FFF;
    margin-bottom: 10px;
    a {
      color: #FFF; } }
  .number {
    font-size: 21px;
    line-height: 26px;
    color: #FFF; }
  .icon-wa {
    width: 80px;
    height: 80px;
    background-image: url(../images/icon-whatsapp.png);
    background-size: contain;
    position: absolute;
    left: calc(50% - 40px);
    top: -30px; }
  @include mobile {
    display: none; } }

.call-box-m {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 100;
  margin: 0;
  text-align: center;
  padding: 0;
  border-top: 1px solid $border;
  text-transform: uppercase;
  display: none;
  li {
    padding: 15px 0;
    list-style: none;
    float: left;
    width: 50%;
    font-weight: 600;
    a {
      i {
        padding-right: 10px; } }
    &:last-child {
      border-left: 1px solid $border;
      a {
        color: #60D36B; } } }
  @include mobile {
    display: block; } }

.lity-iframe {
  background: #000;
  .lity-container {
    max-width: 100%;
    max-height: 100vh;
    height: 100vh; }
  .lity-iframe-container {
    max-height: 100vh !important;
    padding: 0;
    height: 100vh !important; }
  @include maxtablet {
    .lity-container {
      height: auto; }
    .lity-iframe-container {
      height: 0 !important;
      padding: 56.25% 0 0; } } }
