/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */

footer {
  color: $text;
  background-color: #F2F1E9;
  padding-bottom: 60px;
  p {
    font-size: 14px;
    line-height: 20px; }
  .footer-top {
    background-color: #F6F5EE;
    display: flex;
    padding: 25px 0;
    justify-content: space-around;
    align-items: center;
    vertical-align: middle;
    .logo {
      max-width: 220px;
      display: inline-block;
      img {
        max-width: 100%; }
      &:hover {
        opacity: .7; } }
    .to-top {
      text-align: right;
      vertical-align: middle;
      position: relative;
      line-height: 40px;
      a {
        color: $text;
        font-size: 14px;
        line-height: 20px;
        &:hover {
          text-decoration: underline; } } } }
  .footer-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 60px 0;
    font-size: 14px;
    line-height: 20px;
    .item {
      padding: 0 60px;
      &:first-child {
        padding-left: 0; }
      &:last-child {
        padding-right: 0; }
      h4 {
        color: $text;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold; }
      ul {
        li {
          list-style: none;
          a {
            color: $text;
            font-size: 14px;
            line-height: 30px; }
          &.current-menu-item,
          &.current-sub-item {
            a {
              text-decoration: underline; } } } }
      &.subscribe {
        .form-group {
          position: relative; }
        .form-control {
          border: 0;
          outline: 0;
          box-shadow: none;
          border-radius: 0;
          background-color: transparent;
          padding-left: 0;
          max-width: 340px;
          height: 40px;
          border-bottom: 1px solid $border; }
        a,
        button {
          background: transparent;
          position: absolute;
          top: 5px;
          right: 0;
          border: none;
          line-height: 19px;
          padding: 0;
          i {
            display: inline-block;
            width: 21px;
            height: 19px;
            font-size: 21px; }
          &:hover {
            color: $blue; } } }
      &.socmed {
        a {
          font-size: 20px;
          display: inline-block;
          margin-right: 15px;
          color: $text;
          width: 20px;
          height: 20px; }
        .fa-facebook-square {
          &:hover {
            color: #3b5998; } }
        .fa-instagram {
          &:hover {
            color: #e8424e; } }
        .fa-instagram {
          &:hover {
            color: #e8424e; } }
        .fa-youtube {
          &:hover {
            color: #dd2c28; } } } } }
  @include maxtablet {
    .footer-top {
      text-align: left;
      .to-top {
        text-align: center; }
      .container {
        width: 100%; } }
    .footer-menu {
      .item {
        padding: 0 15px;
        margin-bottom: 20px;
        &:last-child {
          padding: 0; } } } }
  @include mobile {
    .footer-top {
      text-align: center; }
    .footer-menu {
      .item {
        width: 100%;
        padding: 0;
        position: relative;
        ul {
          max-height: 0;
          visibility: hidden;
          opacity: 0;
          padding: 0;
          position: relative; }
        h4 {
          margin-bottom: 0;
          &:after {
            content: "\f196";
            font-family: FontAwesome;
            font-weight: normal;
            color: #6F7F99;
            position: absolute;
            right: 0;
            top: 0px; } }
        &:last-child {
          h4 {
            &:after {
              display: none; } } }
        &.open {
          h4 {
            margin-bottom: 20px;
            &:after {
              content: "\f147"; } }
          ul {
            max-height: 9999px;
            visibility: visible;
            opacity: 1;
            padding: 0 0 10px; } }
        &.subscribe {
          h4 {
            margin-bottom: 15px;
            &:after {
              display: none; } } }
        &.socmed {
          h4 {
            margin-bottom: 15px; } } } } } }
