@import 'cores/mixin';

// Color
$primary: #3F474A;
$blue: #2187A4;
$green: #73bf43;
$primary-dark: darken(#004A8F, 5%);
$border: #E2E2E2;
$text: #686E70;
$link: #2187A4;



/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/layout';
@import 'cores/base';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/general';
@import 'components/custom-owl';
@import 'components/custom-date';
@import 'components/header';
@import 'components/footer';
@import 'components/form';
@import 'components/content';
