/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */
.custom-form {
  .form-group {
    margin: 0 auto 20px; }
  .form-control {
    min-height: 40px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $border;
    outline: 0;
    box-shadow: none;
    color: $text;
    font-weight: 500;
    &::placeholder {
      color: $text;
      font-size: 16px;
      line-height: 26px;
      font-weight: 500; } }
  &.form-brochure {
    .form-group {
      width: 300px; }
    .form-control {
      text-align: center; } }
  &.form-contact {

    .form-group {
      margin-bottom: 15px;
      &.last {
        margin-bottom: 0; } }
    .form-control {
      min-height: 50px;
      padding: 12px 0;
      font-size: 16px;
      line-height: 26px; }
    textarea {
      &.form-control {
        resize: none; } }
    .form-action {
      padding-top: 30px;
      text-align: center; } } }

.form-brochure,
.form-contact {
  .form-group {
    width: 100% !important; }
  .gform_wrapper {
    width: 100%;
    margin: 0;
    .gform_body {
      width: 300px;
      margin: 0 auto; }
    .top_label {
      .gfield_label {
        display: none !important; } }
    .validation_error {
      display: none; } }
  .ginput_container {
    margin-top: 0;
    margin-bottom: 15px;
    input,
    textarea {
      width: 100% !important;
      text-align: center;
      border: 0;
      border-bottom: 1px solid $border;
      min-height: 40px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $border;
      outline: 0;
      box-shadow: none;
      color: $text;
      font-weight: 500;
      &::placeholder {
        color: $text;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; } } }
  .gform_footer {
    text-align: center;
    input[type=submit] {
      background-color: $primary;
      border: 1px solid $primary;
      padding: 0 30px;
      display: inline-block;
      line-height: 38px;
      border-radius: 20px;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
      color: #FFF;
      &:hover,
      &:active,
      &.active {
        background-color: darken($primary, 10%);
        border: 1px solid darken($primary, 10%);
        color: #FFF; }
      &.w-arrow {
        .fal {
          display: inline-block;
          vertical-align: middle;
          // position: absolute
          // right: 15px
          // top: calc(50% - 7px)
          opacity: 0;
          transition: all 0.5s; }
        &:hover,
        &:active,
        &.active {
          padding-left: 25px;
          .fal {
            opacity: 1;
            width: auto;
            padding-left: 10px; } } } } } }
.form-contact {
  .form-group {
    width: 100% !important; }
  .gform_wrapper {
    width: 100%;
    margin: 0;
    .gform_body {
      width: 100%; } }
  .ginput_container {
    input,
    textarea {
      text-align: left; }
    textarea {
      resize: none; } }
  .gform_confirmation_message {
    font-size: 24px;
    line-height: 1.5; } }

.gfield_error {
  margin: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background: transparent !important;
  .ginput_container {
    margin: 0 !important;
    margin-bottom: 0 !important;
    input,
    textarea {
      border: 0 !important;
      border-bottom: 1px solid red !important; } }
  .validation_message {
    margin: 0 !important;
    padding-top: 0 !important;
    padding: 0 !important;
    font-weight: 400 !important;
    color: red !important; } }

