/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */

.masthead {
  position: relative;
  margin-bottom: 60px;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: .5; }
  .owl-stage-outer {
    padding-top: 0; }
  .item {
    @include bgcover();
    background-repeat: no-repeat;
    background-position: center;
    .container {
      flex-flow: row wrap;
      flex-direction: column;
      min-height: 100vh;
      text-align: center;
      color: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1; }
    h2 {
      font-weight: bold;
      font-size: 64px;
      line-height: 74px;
      margin: 0 auto;
      max-width: 965px;
      padding: 0 15px;
      margin-bottom: 30px;
      text-transform: uppercase; }
    .line-logo {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 60px;
      .l-logo {
        display: inline-block;
        max-height: 60px;
        margin-top: 10px;
        max-width: 25%;
        height: auto;
        img {
          max-height: 60px; } } }
    .arrow-down {
      position: absolute;
      bottom: 40px;
      width: 40px;
      text-align: center;
      color: #fff;
      left: calc(50% - 20px);
      font-size: 36px;
      line-height: 36px;
      z-index: 3;
      animation: mouse-wheel 1.5s linear infinite; }
    p {
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      font-weight: 600; } }
  @include maxtablet {
    margin-bottom: 30px;
    .item {
      .tagline {
        max-width: 200px;
        height: auto;
        .img {
          max-width: 100%;
          max-height: 100%; } }

      .line-logo {
        width: calc(90% - 30px);
        .l-logo {
          .img {
            max-height: 40px; } } } } }
  @include mobile {
    .slider-mh {
      padding-bottom: 0; }
    .item {
      .line-logo {
        width: calc(100% - 30px);
        display: block;
        bottom: 20px;
        .l-logo {
          margin-bottom: 10px;
          padding: 0 5px;
          margin-top: 10px;
          .img {
            max-height: 25px; } } } } } }


.masthead-small {
  margin-bottom: 0;
  .slider-mh {
    .owl-stage-outer {
      padding-top: 0; } }
  .item {
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #000;
      opacity: .1;
      z-index: 2; }
    .container {
      min-height: 60vh;
      position: relative;
      z-index: 3; }
    .tagline {
      .title {
        font-size: 24px;
        line-height: 1.5em;
        color: #FFF;
        margin-bottom: 30px;
        position: relative;
        &:after {
          content: "";
          width: 100px;
          height: 3px;
          background-color: #2187A4;
          display: block;
          margin: 15px auto 0; } }
      p {
        font-size: 28px;
        line-height: 1.5em; } } }
  @include responsive(1400px) {
    .item {
      .container {
        min-height: 75vh; } } }
  @include maxtablet {
    .item {
      .tagline {
        text-align: center;
        max-width:  100%;
        p {
          text-align: center; } }
      .tagline {
        .title {
          font-size: 21px; }
        p {
          font-size: 24px; } } } }
  @include mobile {
    .slider-mh {
      padding-bottom: 0; } } }

.masthead-product {
  .item {
    .container {
      text-align: left;
      align-items: flex-start;
      justify-content: left;
      min-height: 90vh;
      .tagline {
        margin-top: 30px;
        .img {
          max-height: 80px; } } } } }

.home-popover {
  max-width: 345px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  border-radius: 4px;
  z-index: 3;
  .x-close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: url(../images/icon-close.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: #424242;
    z-index: 4; }
  .box-img {
    position: relative;
    width: 100%;
    padding-top: 98.552%;
    .img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: all .5s; } }
  .box-text {
    text-align: center;
    font-weight: 600;
    padding: 30px;
    background: #FFF;
    font-size: 16px;
    line-height: 26px;
    p {
      margin-bottom: 10px; } }
  .btn-link {
    color: #2187A4;
    opacity: 1;
    &:after {
      background-color: #2187A4;
      opacity: 1;
      margin: 0 auto; }
    &:hover {
      opacity: .8;
      &:after {
        opacity: .8; } } }
  @include responsive(1400px) {
    transform: scale(.7);
    right: -15px;
    bottom: -45px; }
  @include maxtablet {
    // display: none
    right: 0;
    left: 0;
    margin: 0 auto; } }


.home-content {
  .title {
    margin-bottom: 60px;
    p {
      text-align: left; } }
  .box-video {
    position: relative;
    padding-top: 48.246%;
    z-index: 3;
    // min-height: 550px
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      min-height: 550px;
      @include maxtablet {
        min-height: auto; } }
    .video-embed {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // display: none
      z-index: 2;
      iframe {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0; }
      .modal-close {
        cursor: pointer;
        color: #FFF;
        font-size: 14px;
        line-height: 20px;
        position: absolute;
        right: 25px;
        top: 25px;
        text-align: center;
        opacity: 1;
        transition: opacity .5s;
        display: none;
        transition: all .5s ease;
        span {
          display: block; }
        &:before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background-image: url(../images/icon-close.svg);
          margin: 0 auto 10px; } } } }
  .content-text-wrap {
    position: relative;
    padding: 60px 0;
    &:before {
      content: "";
      background: linear-gradient(314.57deg, #316CAB 0%, #2187A4 100%);
      width: 200%;
      height: calc(100% + 200px);
      display: block;
      position: absolute;
      right: 200px;
      top: -200px;
      z-index: 1; }
    .content-text {
      max-width: 760px;
      column-count: 2;
      color: #FFF;
      position: relative;
      z-index: 2;
      p {
        padding-right: 30px; } } }
  @include maxtablet {
    h3.title {
      text-align: left; }

    .box-video {
      max-width: 100%;
 }      // min-height: 338px
    .content-text-wrap {
      padding: 30px 0;
      &:before {
        right: 0;
        top: -180px; } } }
  @include mobile {
    .content-text-wrap {
      padding: 30px 15px;
      background: linear-gradient(314.57deg, #316CAB 0%, #2187A4 100%);
      &:before {
        display: none; }
      .content-text {
        column-count: 1;
        p {
          padding-right: 0; } } } } }


.home-product {
  .content-text {
    max-width: 770px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 60px; }
  .title {
    margin-bottom: 20px; }
  .product-list {
    position: relative;
    .item {
      // max-width: 900px
      transition: all .5s linear; }
    .card {
      position: relative;
      padding-left: 10px;
      transition: all .5s linear;
      h4 {
        font-weight: 600;
        // // transform: rotate(-90deg)
        // writing-mode: vertical-lr;
        // transform: rotate(-180deg);
        // transform-origin: left right;
        // font-weight: 600
        // text-transform: uppercase
        // font-size: 14px
        // line-height: 20px
        // margin-bottom: 0
        // position: absolute
        // left: -15px
 }        // top: 0
      .box-img {
        // padding-top: 90.91%
        padding-top: 120%;
        margin-bottom: 0; }
      .box-text {
        opacity: 0;
        transition: all .5s;
        visibility: hidden;
        padding: 20px 0;
 } }        // max-height: 0
    .center {
      .item {}
      .card {
        // transform: translateY(-60px)
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5);
        transform: scale(1.2) translateX(-131.5px);

        min-width: 650px;
        // left: -35px
        background: #FFF;
        z-index: 80;
        padding-left: 0;
        margin-bottom: 0;
        .box-img {
          // padding-top: 147.06%
          padding-top: 55%; }
        .box-text {
          visibility: visible;
          opacity: 1;
          transform: scale(.9);
 } } } }          // max-height: 99999px
  .slider-p {
    .owl-stage-outer {
      padding-bottom: 50px; }
    .owl-stage {
      padding-top: 60px; }
    .owl-nav {
      top: calc(40% - 15px);
      &.disabled {
        display: block !important; } } }
  @include maxtablet {
    .product-list {
      .slider-p {
        margin-left: 0;
        margin-right: 0;
        .owl-nav {
          .owl-prev {
            left: -15px; }
          .owl-next {
            right: -15px; } }
        .center {
          .item {}
          .card {
            transform: scale(1.2) translateX(-15%);
            min-width: 400px; } }
        .owl-nav {
          top: calc(30% - 15px); } } } }
  @include mobile {
    .product-list {
      .slider-p {
        .owl-nav {
          top: calc(50% - 15px);
          .owl-prev {
            left: -10px; }
          .owl-next {
            right: -10px; } }
        .owl-stage-outer {
          padding-bottom: 0; }
        .card {
          min-width: 300px;
          .box-img {
            padding-top: 55%; } }
        .center {
          .item {
            padding: 0 15px; }

          .card {
            transform: scale(1) translateY(0) translateX(0);
            // transform: translateY(-60px)
            min-width: 300px;
            .box-img {
 } } } } } } }              // padding-top: 100%

.home-press {
  .title {
    margin-bottom: 60px; }
  .btn-link {
    color: #2187A4;
    opacity: 1;
    &:after {
      opacity: 1;
      background-color: #2187A4; }
    &:hover {
      opacity: .8;
      &:after {}
      opacity: .8; } }
  .press-l {
    margin-bottom: 30px; }
  .card {
    // margin-bottom: 0
    .box-img {
      padding-top: 76.2%; }
    .date {
      font-size: 14px;
      line-height: 20px;
      display: inline-block;
      margin-bottom: 10px; }
    h4 {
      font-size: 16px;
      line-height: 24px; } }
  @include mobile {
    .title {
      margin-bottom: 30px; }
    .card {
      margin-bottom: 30px; } } }

.products-page {
  .products-list {
    padding-bottom: 60px; } }

.product-detail {
  .masthead {
    margin-bottom: 0; } }

.products-list {
  .card {
    width: 100%;
    margin-bottom: 60px;
    .box-img {
      padding-top: 55.556%; }
    .title {
      font-size: 18px;
      line-height: 28px; }
    .p-detail {
      margin-bottom: 30px;
      dt {
        float: left;
        font-weight: 400;
        color: #686E70; }
      dd {
        padding-left: 95px;
        margin-bottom: 5px;
        color: #686E70; } } } }

.milestone {
  padding-top: 0;
  .milestone-box {
    margin: 0 auto;
    max-width: 900px;
    padding: 30px 0 0;
    // border-left: 2px solid $border
    .step-box {
      // padding-left: 30px
      // margin-bottom: 30px
      background: {}
      position: relative;
      top: 7px;
      width: 450px;
      padding: 20px;
      .title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
        position: relative {
            bottom: 0; } }
      &:before {
        content: '';
        background: $blue;
        position: absolute;
        top: 32px;
        width: 38px;
        height: 5px; } } }
  ul {
    li {
      position: relative;
      margin: 0 auto;
      width: 5px;
      // padding-bottom: 40px
      list-style-type: none;
      background: $blue;
      &:before {
        content: '';
        background: $blue;
        position: absolute;
        left: 50%;
        top: 32px;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        width: 20px;
        height: 20px;
        border: 3px solid $border;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%; }

      &:nth-child(odd) .step-box {
        left: 50px; }

      &:nth-child(odd) .step-box:before {
        left: -38px; }

      &:nth-child(even) .step-box {
        left: calc(-450px - 45px); }

      &:nth-child(even) .step-box:before {
        right: -38px; }

      ul,
      ol {
        padding-left: 25px;
        > li {
          margin: inherit;
          width: auto;
          background: transparent;
          list-style: initial;
          padding-left: 10px;
          margin-bottom: 15px;
          &:before {
            display: none; } } } } }


  @include maxtablet {
    max-width: 100%;
    .milestone-box {
      max-width: 100%;
      .step-box {
        width: 320px; } }
    ul {
      li {
        &:nth-child(even) .step-box {
          left: calc(-320px - 45px); } } } }
  @include mobile {
    .milestone-box {
      max-width: calc(100vw - 30px);
      .step-box {
        width: calc(100vw - 75px);
        padding: 10px;
        &:before {
          width: 25px;
          top: 22px; } } }
    ul {
      li {
        margin: initial;
        &:nth-child(odd) .step-box {
          left: 45px; }
        &:nth-child(even) .step-box {
          left: 45px; }
        &:nth-child(even) .step-box:before {
          left: -33px; }
        &:before {
          top: 22px; } } } } }

.contact-page {
  margin-bottom: 15%;
  .title {
    margin-bottom: 60px; }
  @include maxtablet {
    .title {
      margin-bottom: 30px; } }
  @include mobile {
    .title {
      margin-bottom: 15px; } } }

.address-box {
  h4 {
    font-size: 18px;
    line-height: 28px;
    color: #3F474A;
    margin-bottom: 10px;
    font-weight: bold; }
  ul {
    list-style: none;
    li {
      margin-bottom: 20px;
      a {
 }        // padding-left: 15px
      p {
        margin-bottom: 0; } } }
  strong {
    color: #3F474A; }
  .icon-wa {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url(../images/icon-whatsapp.png);
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;
    position: relative;
    margin-right: 10px; } }

.map {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 66.668%;
  z-index: 1; }

.tagline {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
  @include mobile {
    font-size: 18px;
    line-height: 28px; } }

.static-body {
  article {
    .img {
      max-width: 100%;
      display: block;
      text-align: center;
      margin: 60px auto; }
    p {
      max-width: 80%; } }
  ul,
  ol {
    padding-left: 30px; }
  @include mobile {
    article {
      p {
        max-width: 100%; } } } }

.content-text,
article {
  ul,
  ol {
    padding-left: 30px;
    margin-bottom: 15px;
    li {
      padding-left: 10px; } }
  img {
    &.aligncenter {
      display: block;
      margin: 0 auto; }
    &.alignright {
      float: right; } }
  &.alignleft {
    float: left; } }

.careers-body {
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #3F474A; }
  .how-to {
    margin-bottom: 30px; } }

.careers-img {
  @extend .clearfix;
  margin-bottom: 30px;
  .img {
    width: 100%;
    background: #E2E2E2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: -webkit-transform .6s;
    transition: transform .6s;
    transition: transform .6s,-webkit-transform .6s; }
  .img-lg {
    margin-bottom: 10px;
    .img {
      padding-top: 72.074%; } }
  .img-md,
  .img-sm {
    float: left; }
  .img-md {
    width: 54.055%;
    background: linear-gradient(314.57deg, #316CAB 0%, #2187A4 100%);
    .text {
      color: #FFF;
      padding: 60px 35px 65px;
      span {
        font-size: 14px;
        line-height: 20px; } } }
  .img-sm {
    width: calc(45.94% - 10px);
    margin-left: 10px;
    .img {
      padding-top: 110%; } }
  .img-lg,
  .img-md,
  .img-sm {
    overflow: hidden;
    &:hover {
      .img {
        transform: scale(1.03); } } }
  @include maxtablet {
    .img-lg,
    .img-sm {
      .img {
        padding-top: 71.019%; } }
    .img-md,
    .img-sm {
      width: 100%;
      margin-left: 0; }
    .img-md {
      margin-bottom: 10px; } } }

.position-list {
  .position {
    display: block;
    padding: 20px 0;
    font-weight: 800;
    position: relative;
    width: 100%;
    transition: all .5s;
    &.collapsed {
      h4 {
        &:after {
          transform: rotate(-90deg); } } }
    h4 {
      margin-bottom: 0;
      position: relative;
      color: #2187A4;
      &:after {
        content: "\f0d7";
        font-family: FontAwesome;
        font-size: 16px;
        position: absolute;
        right: 0;
        color: $text;
        transition: -webkit-transform .6s;
        transition: transform .6s;
        transition: transform .6s, -webkit-transform .6s; } } }
  .detail-position {
    strong {
      display: inline-block;
      margin-bottom: 15px; }
    ul {
      padding-left: 30px;
      margin-bottom: 30px;
      li {
        padding-left: 10px; }
      &:last-child() {
        margin-bottom: 0; } } }
  .panel {
    border: none;
    border-bottom: 1px solid $border;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    .panel-body {
      padding: 0;
      padding-bottom: 20px; } } }



.press-filter {
  list-style: none;
  text-align: right;
  vertical-align: middle;
  position: relative;
  line-height: 46px;
  li {
    display: inline-block;
    margin-right: 30px;
    &:last-child {
      margin-right: 0; }
    a {
      color: #686E70; }
    &.current,
    &:hover {
      a {
        color: $blue; } } }
  @include maxtablet {
    text-align: right;
    margin-bottom: 15px;
    li {
      margin-right: 20px; } }
  @include mobile {
    text-align: left; } }


.press-list {
  .card {
    .box-img {
      padding-top: 76.196%; }
    .date-time {
      font-size: 14px;
      line-height: 20px;
      color: #98A3A7;
      margin-bottom: 15px; }
    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 20px;
      max-height: 50px;
      // min-height: 50px
      overflow: hidden;
      a {
        color: #2187A4;
        &:hover {
          color: #3F474A; } } } }
  .title.main {
 }    // margin-bottom: 10px
  @include maxtablet {
    .card {
      .title {
        // max-height: 50px
        // min-height: 50px
        overflow: hidden; } } } }

.pagination {
  margin-top: 0;
  text-align: center;
  .load {
    text-align: center;
    display: inline-block;
    &:after {
      content: "";
      opacity: .4;
      width: 70px;
      height: 2px;
      display: block;
      background-color: #2187A4;
      opacity: 1;
      transition: all .5s; }
    &:hover {
      cursor: pointer; } } }
.page-numbers {
  text-align: center;
  padding-left: 0;
  li {
    list-style: none;
    display: inline-block;
    margin: 0 2px;
    padding-bottom: 5px;
    &:hover {
      a,span {
        border-bottom: 1px solid $border; } }
    a,span {
      display: block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 2px;
      color: #686E70;
      font-size: 14px;
      vertical-align: middle;
      margin: 0;
      padding: 0;
      text-decoration: none;
      border: 0; }
    .current {
      border-bottom: 1px solid $border; }
    &.more {
      &:hover {
        a,span {
          border-bottom: 0; } } } }
  a {
    padding: 0 10px;
    font-size: 18px;
    &:hover {
      text-decoration: underline; }
    &.current {
      &:hover {
        text-decoration: none; } } }
  .prev,
  .next {
    font-size: 0;
    position: relative;
    &:before {
      content: "";
      font-family: FontAwesome;
      font-size: 18px;
      position: relative;
      transition: -webkit-transform .6s;
      transition: transform .6s;
      transition: transform .6s, -webkit-transform .6s; } }
  .next {
    &:before {
      content: "\f105"; } }
  .prev {
    &:before {
      content: "\f104"; } } }

.press-detail {
  .masthead-press {
    margin-bottom: 0;
    .item {
      padding-top: 63.159%; } }
  .event-time {
    margin-top: 75px;
    padding-top: 30px;
    border-top: 1px solid $border;
    font-size: 14px;
    line-height: 20px;
    p {
      margin-bottom: 20px; }
    .share {
      p {
        margin-bottom: 5px; }
      a {
        display: inline-block;
        color: $text;
        text-decoration: underline;
        margin-right: 10px;
        &:last-child {
          margin-right: 0; }
        &:hover {
          text-decoration: none; } } } }
  .content-text {
    border-bottom: 1px solid $border; }
  .press-list {
    padding-bottom: 60px; }
  @include mobile {
    .event-time {
      margin-top: 30px;
      margin-bottom: 30px; } } }



.section-slider {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  .quote {
    margin-bottom: 60px;
    .title {
      &:nth-child(2) {
        font-size: 28px;
        line-height: 40px; } } }

  .full-slider,
  .projects-slider,
  .sf-slider {
    margin-bottom: 10px;
    .item {
      // width: 100%
      padding-bottom: 48.246%;
      // background-color: #6F7F99
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative; }
    &.owl-carousel {
      overflow: visible;
      .owl-stage-outer {
        overflow: visible; } }
    .owl-nav {
      // width: 99vw
      // left: -33.4%
      .owl-prev,
      .owl-next {
        width: 60px;
        height: 60px;
        background: #000000;
        opacity: .9;
        border-radius: 0;
        font-size: 30px;
        color: #FFF;
        .fal {
          top: calc(50% - 15px);
          left: calc(50% - 7.5px); }
        &:hover {
          transform: scale(1); } }
      .owl-prev {
        left: 0; }
      .owl-next {
        right: 0; } } }
  .full-slider {
    max-width: 100%;
    .item.w-video {
      position: relative;
      .play-btn {
        position: absolute;
        position: absolute;
        width: 70px;
        height: 70px;
        top: calc(50% - 35px);
        left: calc(50% - 35px);
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #000;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        transition: all .3s;
        z-index: 5;
        i {
          margin-left: 5px; }
        &:hover {
          background-color: #FFF;
          color: #2187A4; } } } }

  .projects-slider,
  .sf-slider {
    &.owl-carousel {
      overflow: hidden;
      .owl-stage-outer {
        overflow: hidden; } }
    .owl-dots {
      text-align: right;
      padding-right: 30px;
      .owl-dot {
        margin: 0 10px;
        span {
          opacity: .5; }
        &.active {
          span {
            opacity: 1;
            background-color: #FFFFFF; } } } }
    .owl-nav {
      .owl-prev {
        left: -60px;
        opacity: 0; }
      .owl-next {
        right: -60px;
        opacity: 0; } }
    &:hover {
      .owl-nav {
        .owl-next,
        .owl-prev {
          opacity: .8; }
        .owl-prev {
          left: 0; }
        .owl-next {
          right: 0; } } } }

  .sf-slider {
    .img {
      padding-bottom: 48.246%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 30px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15); }
    .box-desc {
      h4 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px; } }
    .owl-nav {
      top: calc(50% - 60px); }
    @include maxtablet {
      .box-desc {
        h4 {
          font-size: 21px;
          line-height: 1.5em; } } } }

  @include maxtablet {
    .full-slider {
      .owl-nav {
        top: calc(50% - 0px);
        .owl-prev,
        .owl-next {
          width: 40px;
          height: 40px;
          font-size: 20px;
          .fal {
            top: calc(50% - 10px);
            left: calc(50% - 5px); } } }
      .item {
        width: 500px; } } }
  @include mobile {
    .full-slider {
      .owl-nav {
        top: calc(50% - 0px);
        .owl-prev,
        .owl-next {
          width: 30px;
          height: 30px;
          font-size: 10px;
          .fal {
            top: calc(50% - 5px);
            left: calc(50% - 3px); } } }
      .item {
        width: 250px; } } } }

.section-dbrocure {
  background: #C7E2E8;
  dl {
    margin-bottom: 60px; }
  .btn-link {
    color: #2187A4;
    display: inline-block;
    opacity: 1;
    margin-bottom: 20px;
    margin-right: 15px;
    &:last-child {
      margin-bottom: 0;
      rgin-right: 0; }
    &:after {
      opacity: 1;
      background-color: #2187A4; }
    &:hover {
      opacity: .8;
      &:after {
        opacity: .8; } } }
  @include mobile {
    dl {
      margin-bottom: 30px; } } }

.brocure-detail {
  padding-top: 0; }
.menu-content {
  border-bottom: 1px solid $border;
  ul:not(.sub-wrap) {
    font-size: 0;
    line-height: 0;
    text-align: center;
    & > li {
      list-style: none;
      display: inline-block;
      height: 100%;
      padding: 20px;
      position: relative;
      > a {
        text-align: center;
        padding: 0 20px;
        font-size: 14px;
        line-height: 20px;
        color: #686E70;
        position: relative;
        &:hover {
          color: #2187A4; } }
      &.has-sub {
        >a {
          pointer-events: none;
          &:after {
            content: "\f0d7";
            font-family: FontAwesome;
            font-size: 12px;
            position: absolute;
            right: 3px;
            color: $text;
            top: 0px;
            transition: -webkit-transform .6s;
            transition: transform .6s;
            transition: transform .6s, -webkit-transform .6s; } }
        &:hover {
          > a {
            &:after {
              transform: rotate(-180deg);
              &:hover {
                color: #2187A4; } } } } }
      &.has-sub:hover .sub-menu {
        max-height: 999px;
        opacity: 1;
        // padding: 15px 30px
        visibility: visible; }
      .sub-menu {
        position: absolute;
        left: calc(50% - 110px);
        top: calc(100%  + 3px);
        width: 220px;
        background-color: #fff;
        text-align: left;
        max-height: 0;
        opacity: 0;
        border-radius: 2px;
        visibility: hidden;
        transition: all .5s;
        padding: 15px 30px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        border: 1px solid #e2e2e2;
        z-index: 10;
        ul {
          display: block;
          margin: 0 auto;
          & > li {
            list-style: none;
            font-size: 14px;
            line-height: 30px;
            &.current-sub-item,
            &.current-menu-item {}
            a {
              font-size: 14px;
              line-height: 20px;
              color: #686E70;
              &:hover {
                color: #2187A4; } }
            &.active {
              a {
                color: #2187A4; } } } }
        .sub-wrap {
          &:before {
            position: absolute;
            content: "";
            border-bottom: 13px solid #e2e2e2;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            width: 0;
            height: 0;
            top: -12px;
            left: calc(50% - 11px);
            z-index: 3; }
          &:after {
            position: absolute;
            content: "";
            border-bottom: 13px solid #fff;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            width: 0;
            height: 0;
            top: -10px;
            left: calc(50% - 11px);
            z-index: 3; } }
        .sub-menu:before {
          border-bottom-color: #e2e2e2;
          top: -12px;
          z-index: 2; } } } }

  @include mobile {
    z-index: 10;
    ul:not(.sub-wrap) {
      & > li {
        padding: 20px 15px;
        display: inline-block;
        // min-width: 32.333%
        &:first-child {
          padding-left: 0; }
        &:last-child {
          padding-right: 0; }
        a {
          padding: 0; }
        &.has-sub {
          > a {
            &:after {
              right: -10px; } } }
        .sub-menu {
          width: 150px;
          left: calc(50% - 75px);
          top: calc(100%  + 3px); } } } } }


.detail-content {
  padding-top: 60px;
  .title {
    font-size: 21px;
    line-height: 30px;
    font-weight: normal;
    margin-bottom: 30px; }
  .box-img {
    position: relative;
    width: 100%;
    padding-top: 63.159%;
    margin-bottom: 60px;
    background: #000;
    .img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: all .5s; } }
  .desc-box {
    margin-bottom: 60px; }
  .desc-list {
    .list {
      padding-bottom: 60px;
      border-bottom: 1px solid $border;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0; } }
    .title {
      font-size: 21px;
      line-height: 33px;
      font-weight: 800;
      color: #3F474A;
      margin-bottom: 20px; }
    p {
      margin-bottom: 20px; }
    .subtitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5em;
      color: #3F474A;
      padding-left: 10px; }
    .subtitle2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5em;
      color: #3F474A;
      position: relative;
      padding-left: 10px;
      &:after {
        content: "";
        width: 50px;
        height: 2px;
        background-color: #3F474A;
        display: block; } }

    strong {
      color: #3F474A;
      position: relative;
      &:after {
        content: "";
        width: 60px;
        height: 2px;
        background-color: #3F474A;
        display: block; } }
    ul {
      padding-left: 25px;
      column-count: 2;
      column-gap: 50px;
      li {
        padding-left: 10px;
        line-height: 30px; } }
    dl {
      line-height: 30px;
      margin-bottom: 0;
      dd {
        margin-bottom: 10px; }
      a {
        color: $text;
        &:hover {
          color: $blue; } } }
    .detail-dl2 {
      column-count: 1;
      list-style: none;
      padding-left: 10px; }

    .details {
      .subtitle {
        list-style: none;
        padding-left: 0; }
      ul {
        padding-left: 0;
        li {
          list-style: none;
          > ul {
            padding-left: 25px;
            li {
              list-style: inherit;
              font-size: 14px;
              line-height: 1.5em; } } } } } }

  @include mobile {
    padding: 30px 0;
    .box-img {
      margin-bottom: 30px; }
    .desc-box {
      margin-bottom: 30px; }
    .desc-list {
      .list {
        padding-bottom: 30px; }
      ul {
        column-count: 1; } } } }

.projects-page {
  .box-img {
    position: relative;
    width: 100%;
    padding-top: 63.159%;
    margin-bottom: 60px;
    .img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      object-fit: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: all .5s; } } }

.block-section {
  .block {
    position: relative;
    // min-height: 720px
    margin: 120px 0;
    &:last-child {
      margin-bottom: 0; }
    &:before {
      content: "";
      display: block;
      background-color: #FFF;
      width: 55px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .col-sm-6 {
      position: relative; }
    .box-img {
      margin-bottom: 0;
      max-width: 580px;
      padding-top: 123.079%;
      position: absolute;
      left: 0;
      top:  -60px;
      box-shadow: 0 20px 50px 0 rgba(0,0,0,0.25); }
    &.residential {
      background-color: #2187A4; }
    &.club {
      background-color: #316CAB;
      &:before {
        left: auto;
        right: 0; } }
    .box-text {
      display: flex;
      // min-height: 720px
      padding: 43.155% 60px;
      justify-content: center;
      color: #FFF;
      flex-direction: column;
      text-align: left;
      .title {
        font-size: 30px;
        line-height: 40px;
        color: #FFF;
        margin-bottom: 30px; } } }
  @include maxtablet {
    .block {
      margin: 60px 0;
      &:before {
        width: 33px; }
      .box-img {
        max-width: 345px;
        padding-top: 123.079%;
        left: 15px;
        top:  -30px; }
      .box-text {
        padding: 28% 30px; } } }
  @include maxtablet {
    .block {
      &:before {
        display: none; }
      &.club {
        .col-sm-6 {} }

      .box-img {
        max-width: 100%;
        padding-top: 100%;
        position: relative;
        left: auto;
        top:  auto; }
      .box-text {
        padding: 30px;
        .title {
          font-size: 19px;
          line-height: 33px; } } } } }


.ch-page {
  .caption {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: $blue; }
  .section-part {
    &:nth-child(odd) {
      background: linear-gradient(314.57deg, #316CAB 0%, #2187A4 100%);
      color: #FFF;
      .title,
      .caption {
        color: #FFF; }
      a {
        color: #FFF;
        &:hover {
          opacity: .4; } } } }
  .patt {
    position: relative; } }

.ch-sport {
  .desc {
    margin-bottom: 30px; }
  .row {
    text-align: center; }
  .card-sport {
    width: 33%;
    display: inline-block;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    vertical-align: top;
    margin-bottom: 30px;
    .box-img {
      margin-bottom: 30px;
      padding-top: 100%;
      .txt-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        box-align: center;
        box-pack: center;
        h4 {
          position: relative;
          margin: auto;
          text-align: center;
          color: #FFF;
          font-weight: bold;
          z-index: 5; } } }
    &:hover {
      .box-img {
        .img {
          transform: scale(1.2); }
        &:after {
          // background-color: #73bf43
          opacity: .8; } } }
    .title {
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      color: #3F474A;
      margin-bottom: 20px; } }
  .box-text {
    .box-desc {
      margin-bottom: 30px; }
    .title {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 36px; } }

  @include maxtablet {
    .card-sport {
      width: 32%; } }
  @include mobile {
    .card-sport {
      width: 49%; } } }

.ch-wem {
  .block {
    .cols {
      width: 50%;
      float: left;
      position: relative;
      z-index: 3;
      &.section-slider {
        .projects-slider {
          margin-bottom: 0; }
        .owl-stage-outer {
          padding-top: 0; } } }
    &:nth-child(even) {
      .cols {
        float: right; } } }
  .box-text {
    .box {
      padding: 30px;
      // position: absolute
      // top: 0
      // left: 0
      // bottom: 0
      // right: 0
      // display: flex
      // align-items: center
      // padding: 30px
      .inner-box {
        margin: auto; } }
    .title {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 20px; } }
  .box-img {
    padding-top: 100%;
    margin-bottom: 0; }
  .spec-list {
    margin-bottom: 25px;
    .title {
      font-size: 18px;
      line-height: 28px; } }
  .inner-box {
    ul {
      margin-bottom: 25px;
      padding-left: 30px; } }
  .gh-block {
    .block {
      .cols {
        float: left; } } }
  .wrap-title {
    background-color: #F6F5EE;
    padding: 30px 0; }
  @include maxtablet {
    .block {
      .cols {
 }        // width: 100%
      &:nth-child(odd) {
        .cols {
          float: left; } } }
    .box-img {
      padding-top: 75%; }
    .box-text {
      padding-top: initial;
      .box {
        position: relative;
        height: auto; } } }
  @include mobile {
    .box-text {
      .box {
        padding: 30px 0; } } } }

&.ch-wem {
  padding: 0;
  .item {
    height: auto; }
  .div-full {
    min-height: 100vh;
    position: relative;
    // background: #000
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    box-align: center;
    width: 100%;
    z-index: 2;
    color: #3f474a;
    &:before {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #FFF;
      opacity: .8;
      z-index: 3; }
    &:nth-child(even) {
      &:before {
        right: 0;
        left: auto; } }

    .bg-auto {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .item {
      width: 100%;
      min-height: 100vh;
      background-size: cover;
      background-repeat: no-repeatb;
      background-position: center; }
    .cols {
      position: relative; }
    .owl-dots {
      text-align: right;
      padding: 0 30px; }

    &:nth-child(even) {
      .owl-dots {
        text-align: left; } } }

  @include maxtablet {
    .div-full {
      display: block;
      min-height: auto;
      // height: 40vh
      &:before {
        display: none; }
      .bg-auto {
        position: relative;
        top: auto;
        left: auto; }
      .item {
        min-height: 80vh;
        max-height: 80vh; }
      .owl-dots {
        text-align: center;
        padding: 15px 0; }
      &:nth-child(even) {
        .owl-dots {
          text-align: center; } } }
    .block {
      .cols {
        width: 100%;
        float: none !important; }
      .box {
        // margin: 0 15px
        padding: 15px; } } }
  @include mobile {
    .div-full {
      .item {
        min-height: 60vh;
        max-height: 60vh; } }
    .box-text {
      .title {
        font-size: 21px;
        line-height: 1.5em; } }
    .caption {
      font-size: 18px;
      line-height: 1.5em; } } }

#gh {
  .bg-auto {
    height: 100%;
    .owl-stage-outer {
      height: 100% !important; } }
  .div-full {
    &:before {
      background: #2c74a9; }
    &:nth-child(even) {
      &:before {
        right: auto;
        left: 0; }
      .owl-dots {
        text-align: right; } } }
  .block {
    &:nth-child(even) {
      .cols {
        float: left; } } }
  .inner-box {
    p,
    ul {
      color: #FFF; } }
  @media(min-width: 1400px) {
    h3.title {
      font-size: 36px;
      line-height: 46px; } }
  @include maxtablet {
    .div-full {
      background: linear-gradient(314.57deg, #316CAB 0%, #2187A4 100%);
      .owl-dots {
        text-align: center;
        padding: 15px 0; }
      &:nth-child(even) {
        .owl-dots {
          text-align: center; } } }
    .boxt-text {
      background: red;
      .inner-box {
        .title {
          padding: 0; } } }
    .caption {
      color: #FFF;
      font-size: 22px;
      line-height: 1.5em; }
    .title {
      color: #FFF;
      padding: 0 0 30px 0; } }
  @include mobile {
    .box-text {
      .title {
        font-size: 21px;
        line-height: 1.5em;
        padding-bottom: 0; } }
    .caption {
      font-size: 18px;
      line-height: 1.5em; } } }

.membership {
  // background-color: #C7E2E8
  padding: 60px 0;
  .container-sm {
    max-width: 770px;
    margin: 0 auto; }
  .title {
    margin-bottom: 25px; }
  ul {
    li {
      list-style: none;
      margin-bottom: 20px; } }
  h4.title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0; }
  @include mobile {
    p {
      strong {
        display: block; } } } }

.membership-w {
  text-align: center;
  overflow: hidden;
  p {
    text-align: center; }
  .title,p {
    z-index: 2;
    position: relative; }
  .patt {}
  .pt {
    position: absolute;
    z-index: 1; }
  .pt-01 {
    width: 80px;
    height: auto;
    bottom: 35%;
    right: 0; }

  .pt-02 {
    width: 100px;
    height: auto;
    top: -18%;
    left: 0; }

  .pt-03 {
    width: 60px;
    height: auto;
    bottom: -18%;
    left: 20%; }

  .pt-04 {
    width: 100px;
    height: auto;
    bottom: -18%;
    left: -38%; }

  .pt-05 {
    width: 100px;
    height: auto;
    top: 0;
    right: -44%; } }


.ch-contact {
  // background-color: #C7E2E8
  padding: 60px 0;
  .title {
    margin-bottom: 25px; }
  h4.title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px; }
  .address-box {
    margin-bottom: 30px;
    ul li {
      margin-bottom: 15px; }
    p {
      strong {
        margin-bottom: 15px;
        display: inline-block;
        font-size: 16px; } } }
  @include mobile {
    .address-box {
      p {
        strong {
          display: block; } } } } }

.ch-location {
  .title {
    margin-bottom: 60px; }
  h4.title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px; }
  ul {
    padding-left: 30px;
    margin-bottom: 30px; } }

.menu-right {
  position: fixed;
  right: 0;
  top: calc(50% - 230px);
  padding: 30px;
  background-color: #000;
  opacity: .8;
  border-radius: 4px;
  z-index: 4;
  transition: -webkit-transform .6s;
  transition: transform .6s;
  transition: transform .6s, -webkit-transform .6s;
  .arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    color: #FFF;
    top: 35px;
    left: -30px;
    background-color: #000;
    border-radius: 4px 0 0 4px;
    transition: -webkit-transform .6s;
    transition: transform .6s;
    transition: transform .6s, -webkit-transform .6s;
    display: block;
    cursor: pointer;
    &:before {
      content: "\f0d9";
      font-family: FontAwesome;
      font-size: 18px;
      line-height: 30px;
      display: inline-block; } }
  &.slide-h {
    transform: translateX(100%);
    .arrow {
      &:before {
        transform: rotate(-180deg); } } }
  ul {
    li {
      list-style: none;
      line-height: 40px;
      border-bottom: 1px solid #FFF;
      a {
        color: #FFF; } } }
  @include mobile {
    top: calc(50% - 180px);
    &.slide-h {
      transform: translateX(235px); } } }


.page-notfound {
  text-align: center;
  h2 {
    font-size: 144px;
    line-height: 197px;
    font-weight: 900;
    margin-bottom: 30px;
    position: relative;
    color: $blue;
    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 5px;
      margin: 0 auto;
      color: $blue;
      background-color: $blue; } }
  p {
    text-align: center;
    font-size: 36px;
    line-height: 49px; }
  @include maxtablet {
    h2 {
      font-size: 100px;
      line-height: 120px; }
    p {
      font-size: 16px;
      line-height: 21px; } } }

.map-box {
  width: 100%;
  // padding-top: 31.854%
  padding-top: 41%;
  background-color: #B0B1C3;
  position: relative;
  overflow: hidden;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 15%;
    // background: red
    z-index: 3; }
  .map {
    transition: all .5s;
    padding-top: 0 !important; }
  #map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 25%;
    height: 100%;
    &.slide {
      width: 100%;
      z-index: 4; }
    &.active {
      left: 15%;
      width: 85%;
      z-index: 3; } }
  #app {
    position: absolute;
    top: 0;
    left: -5%;
    bottom: 0;
    right: 0;
    width: 105%;
    height: 100%; } }
.active {
  &.hovered {
    transform: translateX(5%); } }
.box-panorama {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 15%;
  height: 100%;
  box-shadow: 10px 0 30px 20px hsla(0,0%,4%,.5);
  transition: all .5s;
  z-index: 3;
  &.slide {
    width: 100%;
    z-index: 4; }
  &.active {
    left: 15%;
    width: 85%;
    z-index: 3; }
  #bx-panorama {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover !important; }
  .pnlm-compass {
    bottom: auto;
    top: 10px; } }
@include ltablet {
  .map-box {
    padding-top: 50%; } }
@include maxtablet {
  .map-box {
    padding-top: 110%; } }
@include mobile {
  height: calc(100vh - 48px);
  overflow: hidden;
  .map-box {
    padding-top: 100vh; }
  #main-navigation {
    display: none !important; }
  #app {
    .main.content {
      top: 0 !important; } } }
